import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col } from "reactstrap";
import Header from "./header";
import Footer from './NewFooter';
import WOW from '../../animation/Wow';
import { openPopupWidget } from "react-calendly";
import resourcesicon1 from "../../images/new-resources/faq.svg"
import resourcesicon2 from "../../images/new-resources/help_center.svg"
import resourcesicon3 from "../../images/new-resources/free_training.svg"
import resourcesicon4 from "../../images/new-resources/product_road_map.svg"
import resourcesicon5 from "../../images/new-resources/blog.svg"
import employee_svg from '../../images/new-resources/employee.svg'

let pageSettings = {
	backgroundColor: 'ffffff',
	hideEventTypeDetails: false,
	hideLandingPageDetails: false,
	primaryColor: '00a2ff',
	textColor: '4d5055'
},
	// url = 'https://calendly.com/getshifts',
	url = 'https://calendly.com/d/cks-3hf-fbt/demo?month=2022-02',
	prefill = {},
	utm = {};
export default class ReSources extends Component {

	componentDidMount() {
		new WOW().init();
	}
	render() {
		return (
			<>
				<Header></Header>

				<div className="resource_section">
					<div className="container">
						<div className="row">
							<div className="col-lg-3 col-md-12 col-sm-12 row">
								<div className="faq col-lg-12 col-md-6 col-sm-12">
									<img src={resourcesicon1} alt="FAQ" />
									<h4>FAQ</h4>
									<p>	Quickly find answers by referring to our list of commonly
										asked questions.</p>
									<Link to="/faq">Read More<i class="fa-solid fa-play"></i></Link>
								</div>
							</div>
							<div className="col-lg-6 col-md-12 col-sm-12">
								<div className="help_center">
									<h5>Essential <span>Resources</span> For Your <br /> Shift Management</h5>
									<p>Discover valuable tools and insights to streamline your <br /> scheduling and optimize your workforce.</p>
									<img className="mt-3" src={resourcesicon2} alt="Help center" />
									<h4 className="mt-3">Help center</h4>
									<p className="mt-3">Learn how Get Shifts can help you manage your <br/> temporary workforce.</p>
									<Link to="/helpcenter">Read More<i class="fa-solid fa-play"></i></Link>
								</div>
							</div>
							<div className="col-lg-3 col-md-12 col-sm-12 row">
								<div className="blog col-lg-12 col-md-6 col-sm-12">
									<img src={resourcesicon5} alt="Blog" />
									<h4>Blog</h4>
									<p>	Keep up with the latest feature updates, tips, and best
										practices.</p>
									<a href=" https://www.getshifts.co.uk/blog/" >Read More<i class="fa-solid fa-play"></i></a>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-3 col-md-12 col-sm-12 row">

								<div className="free_training mt-4 col-lg-12 col-md-6 col-sm-12">
									<img src={resourcesicon3} alt="Free Training" />
									<h4>Free Training</h4>
									<p>	Simple and easy steps to get you started with Get Shifts.
										Manage your temporary workforce.</p>
									<Link to="" onClick={() => openPopupWidget({ url, prefill, pageSettings, utm })}>Read More<i class="fa-solid fa-play"></i></Link>
								</div>
							</div>
							<div className="col-lg-6 col-md-12 col-sm-12">

								<div className="help_center mt-3">
									<img className="mt-3" src={employee_svg} alt="Employee App Documentation" />
									<h4 className="mt-3">Employee App Documentation</h4>
									<p className="mt-3">Get to know how the employee app works and make the most of Get Shifts.</p>
									<Link to="/appdemo">Read More<i class="fa-solid fa-play"></i></Link>
								</div>
							</div>
							<div className="col-lg-3 col-md-12 col-sm-12 row">
								
								<div className="roadmap mt-4 col-lg-12 col-md-6 col-sm-12">
									<img src={resourcesicon4} alt="Road Map" />
									<h4>Product Roadmap</h4>
									<p>	Keep track of what we are working on upcoming features, fixes, requests and enhancements.</p>
									<Link to={{ pathname: "https://trello.com/b/IVbK9pwF/agency-portal-roadmap" }} target="_blank">Read More<i class="fa-solid fa-play"></i></Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer></Footer>
			</>
		);
	}
}
