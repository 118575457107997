import React, { useEffect, useState } from 'react'
import request, { client, NodeURL } from '../../../api/api'
import Header from '../header'
import Footer from '../NewFooter'
import { Box, Typography, Button, Grid, List, ListItem, ListItemText, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import playButtonIcon from '../../../images/appdemo/play_button.svg'
import pdfIcon from '../../../images/appdemo/pdf.svg'
import login from '../../../images/WebDoc/login.png'
import dashboard from '../../../images/WebDoc/dashboard.png'
import shift from '../../../images/WebDoc/shift.png'
import timesheet from '../../../images/WebDoc/timesheet.png'
import invoice from '../../../images/WebDoc/invoice.png'
import employee from '../../../images/WebDoc/employee.png'
import worklocation from '../../../images/WebDoc/worklocation.png'
import incident from '../../../images/WebDoc/incident.png'
import compalince from '../../../images/WebDoc/compalince.png'
import recruitment from '../../../images/WebDoc/Recruitment.png'
import setting from '../../../images/WebDoc/setting.png'
import logo1 from '../../../images/WebDoc/webDocumentationIntro.jpg'
import { makeStyles } from '@material-ui/core/styles'
import { CheckValue } from '../../common/utils'
import Iframe from '../../common/Iframe/Iframe'

const useStyles = makeStyles(theme => ({
   container: {
      padding: theme.spacing(5),
      borderRadius: '12px',
      textAlign: 'center',
      maxWidth: '1100px',
      margin: 'auto'
   },
   phaseOneContainer: {
      display: 'flex'
   },
   heading1: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
      textAlign: 'left',
      color: '#5F4B95'
   },
   heading2: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
      textAlign: 'left',
      color: '#1A202C'
   },
   text: {
      marginBottom: theme.spacing(2),
      color: '#666',
      fontSize: '14px',
      textAlign: 'left',
      color: '#1A202C'
   },
   buttonsContainer: {
      marginBottom: theme.spacing(5)
   },
   pdfIcon: {
      width: '24px',
      height: '24px',
      marginRight: theme.spacing(1)
   },
   pdfButton: {
      backgroundColor: '#FF5151',
      borderRadius: '8px',
      '&:hover': {
         backgroundColor: '#FF5151'
      }
   },
   playButtonIcon: {
      width: '24px',
      height: '24px',
      marginRight: theme.spacing(1)
   },
   playButton: {
      borderColor: '#3B82F6',
      borderRadius: '8px',
      color: '#3B82F6',
      '&:hover': {
         borderColor: '#3B82F6'
      }
   },
   imageContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(5),
      paddingLeft: '20px'
   },
   image: {
      maxWidth: '100%',
      height: '400px',
      borderRadius: '12px'
   },
   contentContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginTop: theme.spacing(5)
   },
   mainContainer: {
      width: '65%'
   },
   sidebar: {
      padding: theme.spacing(2),
      backgroundColor: '#f5f5ff',
      border: '1px solid #FFFFFF',
      borderRadius: '24px',
      width: '100%',
      display: 'flex'
   },
   tablist: {
      display: 'flex',
      width: '100%',
      gap: '7px'
   },
   mainContent_phase2: {
      flexGrow: 1,
      padding: theme.spacing(2),
      width: '100%',
      textAlign: 'left',
      paddingTop: '60px'
   },
   sectionTitle: {
      fontWeight: 'bold',
      fontSize: '18px',
      // marginBottom: '10px',
      color: '#1A202C'
   },
   sectionActiveTitle: {
      fontWeight: 'bold',
      fontSize: '18px',
      // marginBottom: '10px',
      color: '#5F4B95'
   },
   instructions: {
      fontSize: '14px',
      marginBottom: '10px',
      lineHeight: '1.5',
      color: '#333'
   },
   listItem: {
      margin: theme.spacing(1, 0),
      padding: theme.spacing(1),
      borderRadius: '16px',
      '&:hover': {
         backgroundColor: '#e0e0e0'
      },
      cursor: 'pointer',
      backgroundColor: '#FFFFFF',
      color: '#1A202C'
   },
   activeListItem: {
      backgroundColor: '#5F4B95',
      color: '#FFFFFF'
   },
   videoButton: {
      backgroundColor: '#6200ea',
      color: '#fff',
      '&:hover': {
         backgroundColor: '#3700b3'
      }
   },
   phaseTwoContainer: {
      //   display: 'flex',
      gap: '10px',
      marginTop: '20px'
   },
   accordionContent: {
      display: 'flex'
   },
   instructionsContainer: {
      display: 'block'
   },
   accordionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%'
   },
   accordionTextDiv: {
      width: '100%'
   },
   accordion: {
      border: 'none',
      boxShadow: 'none',
      borderBottom: '1px solid #ccc'
   },
   expandedSummary: {
      border: 'none',
      boxShadow: 'none',
      borderBottom: '1px solid #5F4B95'
   },
   accordionImgDiv: {
      width: '705px',
      //  height: '353px',
      '& img': {
         width: '100%',
         height: '100%',
         objectFit: 'contain'
      }
   }
}))

let initialImageObj = {
   login: login,
   dashboard: dashboard,
   shiftManagement: shift,
   InvoiceManagement: invoice,
   timesheetManagement: timesheet,
   Settings: setting,
   recruitmentModule: recruitment,
   complianceManagement: compalince,
   incidentManagement: incident,
   worklocationmanagement: worklocation,
   employeeManagement: employee
}

const WebDocumentation = () => {
   const classes = useStyles()
   const [activeTab, setActiveTab] = useState('login')
   const [subactiveTab, setsubActiveTab] = useState('employeeManagement')
   const [expanded, setExpanded] = useState('panel1')
   const [imgSrc, setImgSrc] = useState(login)
   const [subimgSrc, setSubImgSrc] = useState(employee)
   const [openIframeModal, setOpenIframeModal] = useState(false)

   const handleTabClick = tab => {
      setImgSrc(initialImageObj[tab])
      setActiveTab(tab)
      setExpanded('panel1')
   }
   const handlesubTabClick = tab => {
      setSubImgSrc(initialImageObj[tab])
      setsubActiveTab(tab)
      setExpanded('panel1')
   }

   const handleAccordionChange = (panel, imgUrl) => isExpanded => {
      setImgSrc(imgUrl)
      setExpanded(isExpanded ? panel : false)
   }

   const handleDownloadPdf = () => {
      const pdfUrl = `${NodeURL}/uploads/pdf/webDocumentation.pdf`
      window.open(pdfUrl, '_blank')
   }

   const handleOnClose = () => {
      setOpenIframeModal(false)
   }

   return (
      <div>
         <Header />
         <div className="viewdemo">
            <Box className={classes.container}>
               <Box className={classes.phaseOneContainer}>
                  <Box className={classes.mainContainer}>
                     <Typography variant="h4" component="h1" gutterBottom className={classes.heading1}>
                        Get Shifts
                     </Typography>
                     <Typography variant="h3" component="h2" className={classes.heading2}>
                        System Documentation
                     </Typography>
                     <Box className={classes.contentContainer}>
                        <Box className={classes.textContainer}>
                           <Typography variant="body1" className={classes.text}>
                              Get Shifts is a comprehensive agency management software designed to automate and streamline various aspects of your business
                              operations. This documentation provides a detailed guide on how to effectively use the Get Shifts platform to manage shifts,
                              staff, compliance, and more.
                           </Typography>
                           <Grid container spacing={2} className={classes.buttonsContainer}>
                              <Grid item>
                                 <Button variant="contained" color="primary" className={classes.pdfButton} onClick={handleDownloadPdf}>
                                    <img src={pdfIcon} className={classes.pdfIcon} alt="pdfIcon" /> Download PDF
                                 </Button>
                              </Grid>
                              <Grid item>
                                 <Button variant="outlined" color="secondary" className={classes.playButton} onClick={() => setOpenIframeModal(true)}>
                                    <img src={playButtonIcon} className={classes.playButtonIcon} alt="playButtonIcon" /> Play Video
                                 </Button>
                              </Grid>
                           </Grid>
                        </Box>
                     </Box>
                  </Box>
                  <Box className={classes.imageContainer}>
                     <img src={logo1} alt="App Screenshot" className={classes.image} />
                  </Box>
               </Box>
               <Box className={classes.phaseTwoContainer}>
                  <Box className={classes.sidebar}>
                     <List className={classes.tablist}>
                        <ListItem
                           button
                           className={`${classes.listItem} ${activeTab === 'login' ? classes.activeListItem : ''}`}
                           onClick={() => handleTabClick('login')}
                        >
                           <ListItemText primary="Login" />
                        </ListItem>
                        <ListItem
                           button
                           className={`${classes.listItem} ${activeTab === 'dashboard' ? classes.activeListItem : ''}`}
                           onClick={() => handleTabClick('dashboard')}
                        >
                           <ListItemText primary="Dashboard" />
                        </ListItem>
                        <ListItem
                           button
                           className={`${classes.listItem} ${activeTab === 'shiftManagement' ? classes.activeListItem : ''}`}
                           onClick={() => handleTabClick('shiftManagement')}
                        >
                           <ListItemText primary="Shift Management" />
                        </ListItem>
                        <ListItem
                           button
                           className={`${classes.listItem} ${activeTab === 'timesheetManagement' ? classes.activeListItem : ''}`}
                           onClick={() => handleTabClick('timesheetManagement')}
                        >
                           <ListItemText primary="Timesheet Management" />
                        </ListItem>
                        <ListItem
                           button
                           className={`${classes.listItem} ${activeTab === 'InvoiceManagement' ? classes.activeListItem : ''}`}
                           onClick={() => handleTabClick('InvoiceManagement')}
                        >
                           <ListItemText primary="Invoice Management" />
                        </ListItem>
                     </List>
                  </Box>

                  <Box className={classes.mainContent_phase2}>
                     {activeTab === 'login' && (
                        <div className={classes.accordionContainer}>
                           <div className={classes.accordionTextDiv}>
                              <Accordion
                                 expanded={expanded === 'panel1'}
                                 className={`${classes.accordion} ${expanded === 'panel1' ? classes.expandedSummary : ''}`}
                                 onChange={handleAccordionChange('panel1', logo1)}
                              >
                                 <AccordionSummary>
                                    <Typography className={classes.sectionActiveTitle}>Logging In</Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>To access your Get Shifts account, follow these steps:</Typography>
                                       <Typography className={classes.instructions}>
                                          1. Open your web browser and navigate to carecompany.getshifts.co.uk/agency-login.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. Enter your registered email address or username in the first field.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. Enter your password in the second field. Make sure to enter it correctly.
                                       </Typography>
                                       <Typography className={classes.instructions}>4. Click the "Login" button to access your dashboard.</Typography>
                                       <Typography className={classes.instructions}>
                                          If you have forgotten your password, click the "Forgot password?" link below the password field and follow the prompts
                                          to reset your password. For employees looking to log in, click the "Employee Login Click Here" link at the bottom of
                                          the login module.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                           </div>
                           {CheckValue(imgSrc) ? (
                              <div className={classes.accordionImgDiv}>
                                 <img src={imgSrc} alt="App Screenshot" className={classes.image} />
                              </div>
                           ) : null}
                        </div>
                     )}
                     {activeTab === 'dashboard' && (
                        <div className={classes.accordionContainer}>
                           <div className={classes.accordionTextDiv}>
                              <Accordion
                                 expanded={expanded === 'panel1'}
                                 className={expanded === 'panel1' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel1')}
                              >
                                 <AccordionSummary>
                                    <Typography className={classes.sectionActiveTitle}>Dashboard</Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          Upon logging in, you will be directed to the main dashboard, which provides a comprehensive overview of your agency's
                                          operations. The dashboard includes:
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          1. Navigation Menu: Located on the left-hand side, it includes links to various sections such as Dashboard, Shifts,
                                          Timesheets, Invoices, Employees, Clients & Work Locations, and more.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. Shifts Calendar: Displays scheduled shifts in a calendar view. You can view shifts by day, week, or month and use
                                          filters to refine what you see.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. Shifts Summary: Shows a graphical representation of the distribution of shifts (Booked, Ongoing, Completed).
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          5. Compliance Tracker: Provides a quick view of compliance statuses, showing counts of Verified, Expiring Soon,
                                          Expired, and Not Available credentials or documents.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          6. Client and Employee Overview: Displays the total number of active and inactive clients and employees.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          7. Timesheets Overview: Provides statistics on the total number of timesheets, distinguishing between Approved and
                                          Pending.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          8. Recent Activity: A log of recent activities including updates on candidate registrations, changes in application
                                          statuses, and other notifications.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          9. Recruitment Metrics: Shows current recruitment statistics including the number of candidates and applications, and
                                          a breakdown of messages to be addressed.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          10. Upcoming Tasks: Displays upcoming tasks to ensure nothing gets missed.{' '}
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                           </div>
                           {CheckValue(imgSrc) ? (
                              <div className={classes.accordionImgDiv}>
                                 <img src={imgSrc} alt="App Screenshot" className={classes.image} />
                              </div>
                           ) : null}
                        </div>
                     )}
                     {activeTab === 'shiftManagement' && (
                        <div className={classes.accordionContainer}>
                           <div className={classes.accordionTextDiv}>
                              <Accordion
                                 expanded={expanded === 'panel1'}
                                 className={expanded === 'panel1' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel1')}
                              >
                                 <AccordionSummary>
                                    <Typography className={classes.sectionActiveTitle}>Shift Management</Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          The Shifts page is crucial for managing and overseeing all shift-related activities within your agency. Here's how to
                                          navigate and utilize the features:
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          1. Access the Shifts page by clicking on the 'Shifts' option in the left-hand side menu.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. The page layout includes a calendar displaying shifts for the selected time frame (month, week, or day).{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>3. Use the calendar view:</Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Change views between Month, Week, and Day at the top of the calendar.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Navigate to different periods using the arrows next to the month and year.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Click on any shift block to view detailed information about that particular shift.
                                       </Typography>
                                       <Typography className={classes.instructions}>4. Shift status indicators:</Typography>
                                       <Typography className={classes.instructions}>○ Open (Red): Shifts that are still open and need filling.</Typography>
                                       <Typography className={classes.instructions}>○ Booked (Green): Shifts that have been successfully booked. </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Accepted (Light Green): Shifts that have been accepted by employees but have not yet commenced.
                                       </Typography>
                                       <Typography className={classes.instructions}>○ Ongoing (Blue): Shifts that are currently in progress.</Typography>
                                       <Typography className={classes.instructions}>○ Completed (Grey): Shifts that have been completed.</Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Expired (Orange): Shifts that are no longer relevant and have expired.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Holiday (Purple): Dates marked as public holidays or special observances.
                                       </Typography>
                                       <Typography className={classes.instructions}>5. Manage shifts:</Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Add a new shift by clicking the '+ New Shift' button and entering details such as the shift date, time, and assigned
                                          employee.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Edit a shift by clicking on it in the calendar and using the 'Edit' option.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Delete shifts by selecting the shift and choosing the 'Delete' option.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          6. Use the search bar to find specific shifts by keywords, and the dropdown menu to filter shifts based on work
                                          location.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          7. Use the 'Download' button to export shift data in various formats, and the 'Import Shifts' button to bulk upload
                                          shift data using a predefined template.{' '}
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                           </div>
                           {CheckValue(imgSrc) ? (
                              <div className={classes.accordionImgDiv}>
                                 <img src={imgSrc} alt="App Screenshot" className={classes.image} />
                              </div>
                           ) : null}
                        </div>
                     )}
                     {activeTab === 'timesheetManagement' && (
                        <div className={classes.accordionContainer}>
                           <div className={classes.accordionTextDiv}>
                              <Accordion
                                 expanded={expanded === 'panel1'}
                                 className={expanded === 'panel1' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel1')}
                              >
                                   <AccordionSummary>
                                    <Typography className={classes.sectionActiveTitle}>Timesheet Management</Typography>
                                 </AccordionSummary>
                                
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          The Timesheets page is a vital tool for tracking and managing employee work hours. Here's how to navigate and utilize
                                          the features:
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          1. Access the Timesheets page by selecting 'Timesheets' from the left-hand side menu.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. The page is divided into two main tabs: 'Current' and 'Archived', allowing you to view active or historical
                                          timesheet records.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. At the top of the page, counters display the number of 'Approved' and 'Pending' timesheets.
                                       </Typography>
                                       <Typography className={classes.instructions}>4. Manage timesheets:</Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Click the '+ New Timesheet' button to manually create a new timesheet entry.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Use the 'Download' button to export timesheet data for offline use or analysis.
                                       </Typography>
                                       <Typography className={classes.instructions}>5. Use filters for efficient searching:</Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Filter timesheets based on client, work location, employee, date range, and status.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          6. The main table displays each timesheet entry with details such as the timesheet number, employee name, work
                                          location, number of shifts, date range, status, and any attachments. Click on any timesheet entry to view more
                                          detailed information or to edit the timesheet.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          7. Adjust the number of entries displayed per page using the dropdown at the bottom of the table, and navigate through
                                          pages using the pagination controls.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                           </div>
                           {CheckValue(imgSrc) ? (
                              <div className={classes.accordionImgDiv}>
                                 <img src={imgSrc} alt="App Screenshot" className={classes.image} />
                              </div>
                           ) : null}
                        </div>
                     )}
                     {activeTab === 'InvoiceManagement' && (
                        <div className={classes.accordionContainer}>
                           <div className={classes.accordionTextDiv}>
                              <Accordion
                                 expanded={expanded === 'panel1'}
                                 className={expanded === 'panel1' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel1')}
                              >
                                 <AccordionSummary>
                                    <Typography className={classes.sectionActiveTitle}>Invoice Management</Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          The Invoices page is a centralised platform for managing all invoicing related to your agency's services. Here's how
                                          to navigate and utilize the features:{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          1. Access the Invoices page by selecting 'Invoices' from the left-hand side menu.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. The page displays a comprehensive list of all invoices generated, with detailed information about each transaction.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. At the top of the page, summary totals are displayed for all invoices, awaiting approval, awaiting payment, and
                                          overdue amounts.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          4. The table lists each invoice with details including Invoice Number, Recipient (To), Work Location, Number of
                                          Shifts, Invoice Date, Due Date, Overdue By days, Amount Paid, Amount Due, and Current Status.
                                       </Typography>
                                       <Typography className={classes.instructions}>5. Create and manage invoices: </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Click the '+ New Invoice' button to create a new invoice manually. Enter the necessary details such as client,
                                          services provided, rate, and terms.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Edit an existing invoice by clicking on the desired invoice entry and updating as required.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Use the 'Download' button to export invoice data in a suitable format for accounting purposes or external use.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          6. Use the search bar to quickly find specific invoices by number, recipient name, or other criteria, and filter
                                          invoices by date range using the 'From Date' and 'To Date' selectors. Additional filtering options include sorting by
                                          the number of shifts, location, or current invoice status.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          7. Adjust the number of invoices displayed per page using the dropdown at the bottom of the table, and navigate
                                          through different pages using the pagination controls.{' '}
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                           </div>
                           {CheckValue(imgSrc) ? (
                              <div className={classes.accordionImgDiv}>
                                 <img src={imgSrc} alt="App Screenshot" className={classes.image} />
                              </div>
                           ) : null}
                        </div>
                     )}
                  </Box>
               </Box>
               <Box className={classes.phaseTwoContainer}>
                  <Box className={classes.sidebar}>
                     <List className={classes.tablist}>
                        <ListItem
                           button
                           className={`${classes.listItem} ${subactiveTab === 'employeeManagement' ? classes.activeListItem : ''}`}
                           onClick={() => handlesubTabClick('employeeManagement')}
                        >
                           <ListItemText primary="Employee Management" />
                        </ListItem>
                        <ListItem
                           button
                           className={`${classes.listItem} ${subactiveTab === 'worklocationmanagement' ? classes.activeListItem : ''}`}
                           onClick={() => handlesubTabClick('worklocationmanagement')}
                        >
                           <ListItemText primary="Work Location Management" />
                        </ListItem>
                        <ListItem
                           button
                           className={`${classes.listItem} ${subactiveTab === 'incidentManagement' ? classes.activeListItem : ''}`}
                           onClick={() => handlesubTabClick('incidentManagement')}
                        >
                           <ListItemText primary="Incident Management" />
                        </ListItem>
                        <ListItem
                           button
                           className={`${classes.listItem} ${subactiveTab === 'complianceManagement' ? classes.activeListItem : ''}`}
                           onClick={() => handlesubTabClick('complianceManagement')}
                        >
                           <ListItemText primary="Compliance Management" />
                        </ListItem>
                        <ListItem
                           button
                           className={`${classes.listItem} ${subactiveTab === 'recruitmentModule' ? classes.activeListItem : ''}`}
                           onClick={() => handlesubTabClick('recruitmentModule')}
                        >
                           <ListItemText primary="Recruitment Module" />
                        </ListItem>
                        <ListItem
                           button
                           className={`${classes.listItem} ${subactiveTab === 'Settings' ? classes.activeListItem : ''}`}
                           onClick={() => handlesubTabClick('Settings')}
                        >
                           <ListItemText primary="Settings" />
                        </ListItem>
                     </List>
                  </Box>

                  <Box className={classes.mainContent_phase2}>
                     {subactiveTab === 'employeeManagement' && (
                        <div className={classes.accordionContainer}>
                           <div className={classes.accordionTextDiv}>
                              <Accordion
                                 expanded={expanded === 'panel1'}
                                 className={`${classes.accordion} ${expanded === 'panel1' ? classes.expandedSummary : ''}`}
                                 onChange={handleAccordionChange('panel1', logo1)}
                              >
                                 <AccordionSummary>
                                    <Typography className={classes.sectionActiveTitle}>Employee Management</Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          The Employees page serves as a central hub for managing all employee-related information and operations. Here's how to
                                          navigate and utilize the features:
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          1. Access the Employees page by selecting 'Employees' from the left-hand side menu.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. The page is divided into two main sections: 'Employees' for current staff and 'Archived Employees' for those who
                                          are no longer active.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. The table lists each employee with details such as Employee Number, Name, App (availability to work), Telephone
                                          Number, Email Address, and Job Role.
                                       </Typography>
                                       <Typography className={classes.instructions}>4. Manage employee records:</Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Click the '+ New Employee' button to enter details for a new staff member manually.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Edit employee details by clicking on an employee's name or the edit icon beside their record to update their
                                          information such as contact details, job role, qualifications, and compliance documents.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Archive employees to remove them from the active list without deleting their record.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          5. Use the search bar to quickly find employees by name, job role, or other criteria, and filter employees by role,
                                          skills, and location to narrow down the search results.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          6. Clicking on an employee's name or the details icon will open their full profile, providing in-depth information
                                          including personal details, employment history, qualifications, compliance documents, and more.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          7. The table headers can be clicked to sort the list by number, name, job role, or other attributes in ascending or
                                          descending order, and you can navigate through pages using the pagination controls.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                           </div>
                           {CheckValue(subimgSrc) ? (
                              <div className={classes.accordionImgDiv}>
                                 <img src={subimgSrc} alt="App Screenshot" className={classes.image} />
                              </div>
                           ) : null}
                        </div>
                     )}
                     {subactiveTab === 'worklocationmanagement' && (
                        <div className={classes.accordionContainer}>
                           <div className={classes.accordionTextDiv}>
                              <Accordion
                                 expanded={expanded === 'panel1'}
                                 className={expanded === 'panel1' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel1')}
                              >
                                 <AccordionSummary>
                                    <Typography className={classes.sectionActiveTitle}>Work Location Management</Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          The Work Locations page is designed to organise and manage various work locations associated with your agency's
                                          clients. Here's how to navigate and utilize the features:
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          1. Access the Work Locations page by selecting 'Clients & Work Locations' and then choosing 'Work Locations' from the
                                          left-hand side menu.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. The page features a dual-panel layout with 'Clients' on the right and 'Work Locations' on the left.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. The Clients panel displays a list of clients each represented by a card that includes the client's name, contact
                                          number, email, and the number of associated branches or locations. Use the search bar above the client cards to
                                          quickly find a specific client.
                                       </Typography>
                                       <Typography className={classes.instructions}>4. Adding and managing work locations:</Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Click the 'New Client' button to add a new client. Fill in the required details such as client name, contact
                                          information, and address.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ To add a new work location, fill out the form on the left panel under 'Add Work Location'. Enter details such as the
                                          location name, associated client, and area, then click the 'Save' button.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Existing locations are listed below the form. Here, you can review and modify details like location name, client,
                                          contact person, telephone number, and area. Each entry has options to edit or delete.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          5. The radius setting is particularly useful for setting up geofencing, which can be used to automate check-ins and
                                          check-outs of employees based on their GPS location relative to the work area.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          6. Use the search bar above the list to quickly find specific areas by name or address, and adjust the number of
                                          entries displayed per page using the dropdown menu.{' '}
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                           </div>
                           {CheckValue(subimgSrc) ? (
                              <div className={classes.accordionImgDiv}>
                                 <img src={subimgSrc} alt="App Screenshot" className={classes.image} />
                              </div>
                           ) : null}
                        </div>
                     )}
                     {subactiveTab === 'incidentManagement' && (
                        <div className={classes.accordionContainer}>
                           <div className={classes.accordionTextDiv}>
                              <Accordion
                                 expanded={expanded === 'panel1'}
                                 className={expanded === 'panel1' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel1')}
                              >
                                 <AccordionSummary>
                                    <Typography className={classes.sectionActiveTitle}>Incident Management</Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          The Incidents page provides a systematic approach to recording, tracking, and resolving incidents reported by
                                          employees. Here's how to navigate and utilize the features:
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          1. Access the Incidents page by selecting 'Incidents' from the left-hand side menu.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. The page displays a table listing all reported incidents, including details such as serial number, incident type,
                                          reported date, reported by, shift number, work location, assignee, status, and actions.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. Incident types describe the nature of the incident (e.g., Harassment, Communication problems, Gossip).{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>4. Manage incidents:</Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Add a new incident by clicking a designated button (not visible in the screenshot but typically present) to log a
                                          new incident. Fill out the necessary information such as the type of incident, details, involved parties, and any
                                          immediate actions taken.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Edit incident details by clicking on an incident entry to update its details or status as the situation progresses
                                          or resolves.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Close incidents once they have been resolved by updating the status to 'Closed'.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          5. Use the search bar at the top of the incidents list to find specific incidents by keywords, such as the type of
                                          incident or the name of the reporter, and apply filters to view incidents based on their status, type, or date.
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                           </div>
                           {CheckValue(subimgSrc) ? (
                              <div className={classes.accordionImgDiv}>
                                 <img src={subimgSrc} alt="App Screenshot" className={classes.image} />
                              </div>
                           ) : null}
                        </div>
                     )}
                     {subactiveTab === 'complianceManagement' && (
                        <div className={classes.accordionContainer}>
                           <div className={classes.accordionTextDiv}>
                              <Accordion
                                 expanded={expanded === 'panel1'}
                                 className={expanded === 'panel1' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel1')}
                              >
                                 <AccordionSummary>
                                    <Typography className={classes.sectionActiveTitle}>Compliance Management</Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          The Compliance page is designed to help manage and monitor the compliance status of your workforce, ensuring that all
                                          required certifications and documents are up-to-date and meet regulatory standards. Here's how to navigate and utilize
                                          the features:
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          1. Access the Compliance page by selecting 'Compliance' from the left-hand side menu.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. The page features a grid layout where each row represents an employee and each column corresponds to a specific
                                          compliance document or requirement.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. Compliance icons indicate the status of documents or requirements:
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Verified (Green Check): The document or requirement has been verified and is in compliance.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Unverified (Red Circle): The document is not verified or submitted.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Expiring Soon (Yellow Triangle): The document or certification will soon expire and needs renewal.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Expired (Red Triangle): The document or certification has expired.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Not Available (Grey Circle): Document or information is not available.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Not Required (Grey Slash): The document or requirement is not necessary for the particular employee.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          4. Compliance categories such as Valid ID, Right To Work, DBS (Disclosure and Barring Service), Proof of Residence,
                                          Driver's License, Education & Qualification, and Professional Membership/License are displayed across the top.
                                          Hovering over or clicking on an icon will display more detailed information, such as the expiry date and any action
                                          required.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          5. To update or review an employee's compliance details, click on the corresponding cell in the grid. This action will
                                          open a detailed view where you can upload new documents, renew existing ones, or update compliance statuses.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          6. Use the 'Documentation' and 'Training' tabs at the top to toggle between different sets of compliance requirements.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          7. Use the search bar at the top of the page to quickly find employees by name or compliance status, and adjust the
                                          number of entries displayed per page using the dropdown menu.{' '}
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                           </div>
                           {CheckValue(subimgSrc) ? (
                              <div className={classes.accordionImgDiv}>
                                 <img src={subimgSrc} alt="App Screenshot" className={classes.image} />
                              </div>
                           ) : null}
                        </div>
                     )}
                     {subactiveTab === 'recruitmentModule' && (
                        <div className={classes.accordionContainer}>
                           <div className={classes.accordionTextDiv}>
                              <Accordion
                                 expanded={expanded === 'panel1'}
                                 className={expanded === 'panel1' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel1')}
                              >
                                 <AccordionSummary>
                                    <Typography className={classes.sectionActiveTitle}>Recruitment Module</Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          The Recruitment Module in Get Shifts is designed to streamline the hiring process, making it easier for agencies to
                                          manage candidates, applications, and compliance. Here's how to navigate and utilize the features:{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          1. To switch to the Recruitment Module, navigate to the top right corner of the dashboard and click on the module
                                          switcher icon, then select "Recruitment Management" from the dropdown menu.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. The Recruitment Dashboard offers a snapshot of key recruitment metrics and activities, including Candidates,
                                          Applications, Messages, and To-Do items. It also displays recent notifications, recent candidates, and upcoming
                                          to-dos.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. The Candidates section is where you can view and manage all your candidates. Navigate to the Candidates tab to see
                                          a list of all candidates along with their details such as name, job role, area, phone, email, application form status,
                                          and registration status. You can filter candidates based on various criteria using the filter options at the top.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          4. To view a candidate's detailed profile, click on their name in the list. This opens the candidate's profile with
                                          sections for Basic Information, Compliance, Availability, Documents, Training, and Update Status. You can edit a
                                          candidate's profile by clicking the Edit button.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          5. The Invitations section allows you to send invitations to potential candidates. Navigate to the Candidates tab and
                                          select Invitations to see the status of sent invitations and register new candidates. Click Send Invitation to invite
                                          new candidates by entering their email and selecting an application form. 6. The To-Do section helps you keep track of
                                          tasks assigned to yourself or other users. Navigate to the To-Do tab to view tasks assigned to yourself or others, and
                                          click Add new ToDo to create a new task. 7. The File Manager is used to organize and access documents related to
                                          candidates. Navigate to the File Manager tab to see folders for each candidate, and click on a candidate's folder to
                                          view and manage their documents. 8. Ensure candidates meet compliance requirements and have necessary training.
                                          Navigate to a candidate's profile and click on Compliance to check the status of their ID, right to work, DBS, and
                                          other compliance documents. Under Training, you can view the training status and expiry dates. 9. While primarily a
                                          recruitment tool, Get Shifts also integrates with shift management for seamless transition from candidate to employee.
                                          Use the Shifts module to assign shifts once candidates are hired, and ensure candidates' availability matches with
                                          shift requirements.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          The General Settings section of the Recruitment Module allows administrators to customize various aspects of the
                                          recruitment process:{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          1. To register as a candidate, access the registration page through the provided link and fill out the registration
                                          form with details such as name, email, phone number, work details, and account details. Click the Register button to
                                          complete the registration process.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. To log in to the Candidate Portal, access the login page and enter your registered email or username and password,
                                          then click the Login button to access your candidate dashboard.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          3. The Candidate Dashboard provides an overview of the candidate's application status, notifications, and interview
                                          schedules. It also displays a profile completion indicator and allows candidates to update their profile to ensure a
                                          complete application.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          4. Candidates can view and edit their profile information, ensuring all details are up-to-date. The profile overview
                                          includes basic information, compliance status, and availability. Click Edit Profile to update personal information,
                                          employment history, skills, and other relevant details in sections such as Personal Details, Employment & Education,
                                          Skills & Work History, and Declaration & Terms. Upload necessary documents, such as IDs, right to work, DBS, driver's
                                          license, and more.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          5. The Compliance section allows candidates to track their compliance status for documents like ID, right to work,
                                          DBS, professional membership, and training. Update or upload missing documents directly from this section.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          6. Use the 'Documentation' and 'Training' tabs at the top to toggle between different sets of compliance requirements.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          With these features, the Get Shifts Recruitment Module provides a comprehensive solution for managing the entire
                                          recruitment process, from candidate registration to onboarding, while ensuring compliance and streamlining
                                          communication between recruiters and candidates.{' '}
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                           </div>
                           {CheckValue(subimgSrc) ? (
                              <div className={classes.accordionImgDiv}>
                                 <img src={subimgSrc} alt="App Screenshot" className={classes.image} />
                              </div>
                           ) : null}
                        </div>
                     )}
                     {subactiveTab === 'Settings' && (
                        <div className={classes.accordionContainer}>
                           <div className={classes.accordionTextDiv}>
                              <Accordion
                                 expanded={expanded === 'panel1'}
                                 className={expanded === 'panel1' ? classes.expandedSummary : ''}
                                 onChange={handleAccordionChange('panel1')}
                              >
                                 <AccordionSummary>
                                    <Typography className={classes.sectionActiveTitle}>Settings</Typography>
                                 </AccordionSummary>
                                 <AccordionDetails className={classes.accordionContent}>
                                    <div className={classes.instructionsContainer}>
                                       <Typography className={classes.instructions}>
                                          The Settings page within the Get Shifts software allows administrators to configure various system settings that
                                          impact how the application functions across different modules. Here's how to navigate and utilize the features:
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          1. Access the Settings page by selecting 'Settings' from the left-hand side menu.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          2. The page is organized into a menu of settings categories on the left and a details panel on the right.
                                       </Typography>
                                       <Typography className={classes.instructions}>3. General Settings: </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Update core company details such as the organisation name, email, telephone and fax numbers, business address, VAT
                                          registration number, and more in the Company Information section.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Add or change the company's profile picture, which may appear on documents or in system-generated emails, in the
                                          Upload Profile Picture section.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>4. Advanced Configuration Options: </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Configure settings specific to trial users of the software, if applicable, in the Trial Settings section.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Adjust broader system settings that affect all users in the System Settings section.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Customize features and functionalities available on the mobile version of the platform in the Mobile App Settings
                                          section.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>5. Operational Settings:</Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Define or modify operational areas for which the company provides services in the Areas section.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Set up and manage different job roles within the company in the Job Roles section.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Configure how employee availability is managed in the Availability Settings section.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Create and adjust templates for shifts that can be reused in the Shift Templates section.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>6. Communication and Notifications: </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Determine how notifications are sent out, such as email, SMS, or push notifications, in the Notification Settings
                                          section.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Modify templates for system-generated emails in the Email Templates section.
                                       </Typography>
                                       <Typography className={classes.instructions}>7. HR and Compliance:</Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Adjust settings that relate to the information captured in staff profiles in the Staff Profile Settings section.
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Define and manage different types of incidents that can be reported within the platform in the Incident Types
                                          section.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Input and update public holidays that will be recognized by the system in the Public Holidays section.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>8. System Maintenance Tools: </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ Access deleted items that can be restored or permanently removed in the Bin section.{' '}
                                       </Typography>
                                       <Typography className={classes.instructions}>
                                          ○ View logs for various system activities to monitor usage and troubleshoot issues in the Logs section.{' '}
                                       </Typography>
                                    </div>
                                 </AccordionDetails>
                              </Accordion>
                           </div>
                           {CheckValue(subimgSrc) ? (
                              <div className={classes.accordionImgDiv}>
                                 <img src={subimgSrc} alt="App Screenshot" className={classes.image} />
                              </div>
                           ) : null}
                        </div>
                     )}
                  </Box>
               </Box>
            </Box>
         </div>
         <Footer />
         {openIframeModal ? (
            <Iframe
               openIframeModal={openIframeModal}
               handleOnClose={handleOnClose}
               videoLink={'https://www.youtube.com/embed/NZRcK1v1V_0?si=74avzHfWH8z8Mc_2'}
            />
         ) : null}
      </div>
   )
}

export default WebDocumentation;
