import React, { useState, useEffect, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import WOW from '../../animation/Wow'
import $ from 'jquery'
import AuthetiCationModal from './AuthetiCationModal'
import Context from '../../api/context'
import { openPopupWidget } from 'react-calendly'
import logo from '../../images/common/agency-logo.png'
import menu from '../../images/common/menu.png'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

let pageSettings = {
   backgroundColor: 'ffffff',
   hideEventTypeDetails: false,
   hideLandingPageDetails: false,
   primaryColor: '00a2ff',
   textColor: '4d5055'
},
   // url = 'https://calendly.com/agencyportal',
   url = 'https://calendly.com/d/cks-3hf-fbt/demo?month=2022-02',
   prefill = {},
   utm = {}

$(window).scroll(function () {
   var scroll = $(window).scrollTop()
   if (scroll > 90) {
      $('#myfrontHeader').addClass('fixed')
   } else {
      $('#myfrontHeader').removeClass('fixed')
   }
})

let Header = props => {
   const [dropdownOpen, setDropdownOpen] = useState(false)
   const toggleFeature = () => setDropdownOpen(prevState => !prevState)
   const [helpcenterOpen, setHelpcenterOpen] = useState(false)
   const toggleHelpcenter = () => setHelpcenterOpen(prevState => !prevState)
   const location = useLocation()

   let menufun = () => {
      document.getElementById('varmenu').classList.add('shower-menus')
      document.getElementById('shbody').classList.add('shower-body')
   }

   hidingmenu = () => {
      document.getElementById('varmenu').classList.remove('shower-menus')
      document.getElementById('shbody').classList.remove('shower-body')
   }

   clomenus = () => {
      document.getElementById('varmenu').classList.remove('shower-menus')
      document.getElementById('shbody').classList.remove('shower-body')
   }

   let upperFun = () => {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
   }

   let [showLoginModal, setShowLoginModal] = useState(false),
      [autheticateTab, setAutheticateTab] = useState('1'),
      pathname = window.location.pathname.substring(1),
      hidingmenu = () => {
         document.getElementById('varmenu').classList.remove('shower-menus')
         document.getElementById('shbody').classList.remove('shower-body')
      },
      clomenus = () => {
         document.getElementById('varmenu').classList.remove('shower-menus')
         document.getElementById('shbody').classList.remove('shower-body')
      }
   let openSigninModal = (e, tab) => {
      setShowLoginModal(true)
      setAutheticateTab(tab)
   }

   let closeSigninModal = e => {
      setShowLoginModal(false)
   }
   const openInNewTab = url => {
      window.open(url, '_blank', 'noreferrer')
   }
   useEffect(() => {
      new WOW().init()
      window.scrollTo(0, 0)
   }, [])

   useEffect(() => {
      window.addEventListener('scroll', onScroll)
   }, [])

   const onScroll = e => { }

   const onClick = () => openPopupWidget({ url, prefill, pageSettings, utm })

   return (
      <>
         <section className="new_home_header home-header front-header" id="myfrontHeader">
            <div className="container">
               <div className="header-sections">
                  <div className='mobile_menu'>
                     <Link to="/">
                        <img className="ags-logo" src={logo} alt="logo" />
                     </Link>
                     <ul className="mobile-logins">
                        <li className="men-clicks">
                           <span id="clickmenu" onClick={menufun}>
                              <img src={menu} alt="logo" />
                           </span>
                        </li>
                     </ul>
                     <ul className="inner-menu menu-gains" id="varmenu">
                        <li className="me-cls">
                           <img id="closeviews" onClick={e => clomenus(e)} className="menu-aft" src="../../../img/front/menu-close.svg" alt="images" />
                        </li>
                        <li className={`${pathname === '' ? 'active' : ''}`}>
                           <Link to="/">Home</Link>{' '}
                        </li>
                        <li className={`${pathname === 'features' ? 'active' : ''}`}>
                           <Dropdown isOpen={dropdownOpen} toggle={toggleFeature} onMouseEnter={toggleFeature} onMouseLeave={toggleFeature}>
                              <DropdownToggle caret>
                                 <Link to="/features">Features</Link>
                              </DropdownToggle>
                              <DropdownMenu>
                                 <DropdownItem active={location.pathname === '/shift-management-software'}>
                                    <Link to="/shift-management-software">Shift Management</Link>
                                 </DropdownItem>
                                 <DropdownItem active={location.pathname === '/timesheet-software'}>
                                    <Link to="/timesheet-software">Timesheets</Link>
                                 </DropdownItem>
                                 <DropdownItem active={location.pathname === '/employee-management-software'}>
                                    <Link to="/employee-management-software">Employee Management</Link>
                                 </DropdownItem>
                                 <DropdownItem active={location.pathname === '/invoicing-software'}>
                                    <Link to="/invoicing-software">Invoicing</Link>
                                 </DropdownItem>
                                 <DropdownItem active={location.pathname === '/compliance-software'}>
                                    <Link to="/compliance-software">Hr & Compliance</Link>
                                 </DropdownItem>
                                 <DropdownItem active={location.pathname === '/recruitment-softwares'}>
                                    <Link to="/recruitment-softwares">Recruitment Management</Link>
                                 </DropdownItem>
                                 <DropdownItem active={location.pathname === '/communication'}>
                                    <Link to="/communication">Communication Management</Link>
                                 </DropdownItem>
                              </DropdownMenu>
                           </Dropdown>
                        </li>

                        <li className={`${pathname === 'pricing' ? 'active' : ''}`}>
                           <Link to="/pricing">Pricing</Link>
                        </li>

                        <li className={`${pathname === 'contact' ? 'active' : ''}`}>
                           <Link to="/contact">Contact</Link>
                        </li>


                        <li className={`${pathname === 'helpcentre' ? 'active' : ''}`}>
                           <Dropdown isOpen={helpcenterOpen} toggle={toggleHelpcenter} onMouseEnter={toggleHelpcenter} onMouseLeave={toggleHelpcenter}>
                              <DropdownToggle caret>
                                 <Link to="#">Help Centre</Link>
                              </DropdownToggle>
                              <DropdownMenu>
                                 <DropdownItem active={location.pathname === 'resources'}>
                                    <Link to="/resources">Resources</Link>
                                 </DropdownItem>
                                 <DropdownItem active={location.pathname === 'viewdemo'}>
                                    <Link to="/helpcentre">Knowledge Base</Link>
                                 </DropdownItem>
                                 {/* <DropdownItem active={location.pathname === 'viewdemo'}>
                                    <Link to="/viewdemo">View Demo</Link>
                                 </DropdownItem>
                                 <DropdownItem active={location.pathname === 'viewdemo'}>
                                    <Link to="/appdemo">App Demo</Link>
                                 </DropdownItem> */}
                                 <DropdownItem active={location.pathname === 'webdocumentation'}>
                                    <Link to="/webdocumentation">System Documentation</Link>
                                 </DropdownItem>
                              </DropdownMenu>
                           </Dropdown>
                        </li>


                        <li className="nav-log">
                           <Link to="/signin">Login</Link>
                        </li>

                        <li className={`${pathname === 'demo' ? 'active' : ''} ags-signin nav-log`}>
                           <Link to="#" className="book_a_demo_view_btn book_demo_cta" onClick={() => window.onBookDemo()}>
                              Book a Demo
                           </Link>
                        </li>

                        <li className="ags-signin nav-log">
                           {' '}
                           <Link to="/signup" style={{ backgroundColor: '#8061B3', color: '#FFFFFF', fontSize: '14px' }}>
                              Get started for free
                           </Link>
                        </li>
                     </ul>
                  </div>

                  <ul className="nav-log-start with_view_demo">
                     <li>
                        {/* <a onClick={e => openSigninModal(e, '1')}> Login </a> */}
                        <Link to="/signin">Login</Link>
                     </li>
                     <li className={`${pathname === 'demo' ? 'active' : ''}`}>
                        <Link to="#" className="book_a_demo_view_btn book_demo_cta" onClick={() => window.onBookDemo()}>
                           Book a Demo
                        </Link>
                     </li>

                     <li className="ags-signin">
                        {' '}
                        <Link to="/signup" style={{ backgroundColor: '#8061B3', color: '#FFFFFF', fontSize: '14px' }}>
                           Get started for free
                        </Link>
                     </li>
                  </ul>
                  <div className="fixed-body act-bdy " hidden id="shbody" onClick={e => hidingmenu(e)}></div>
               </div>
            </div>
         </section>
         <div className="toptoast">
            {showLoginModal === true ? (
               <AuthetiCationModal loginModal={showLoginModal} closeModal={e => closeSigninModal(e)} autheticateTab={autheticateTab} />
            ) : null}
         </div>
      </>
   )
}
export default Header
